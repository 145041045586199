/* eslint-disable */
import {isCIF, isEmptyString, isNIF} from '@/scripts/helpers/StringHelpers';
import {isNull, isObject, length} from '@/scripts/helpers/ObjectHelpers';
import {isArray} from '@/scripts/helpers/ArrayHelpers';
import Vue from 'vue';

let instance;

const Plugin = {
  install: function (Vue) {
    // Makes sure that plugin can be installed only once
    if (this.installed) {
      return;
    }
    this.installed = true;

    instance = new Validations();

    // 1. add global method or property
    Vue.validations = instance;

    // 4. add an instance method
    Vue.prototype.$validations = instance;
  }
};

// Utils para validar diversos tipos de valores.
// Cada metodo valida un tipo de valor en caso de llegar (no vacio)
// Esto es asi para que se pueda validar por ejemplo un campo de email no obligatorio
// Si se quiere que ademas de ser un email sea obligatorio se pueden juntar dos rules: required e isEmail
class Validations {
  constructor() {

  }

  // Valor no nulo ni lleno de espacios
  required(msg) {
    msg = msg || Vue.translations.get('common.required');
    let re = /^\s+$/;
    return (v) => {
      if (isNull(v) || re.test(v)) {
        return msg;
      }
      return (v.toString(10).length) ? true : msg;
    }
  }

  // Numero minimo de caracteres
  minChars(minValue = 2, msg) {
    msg = msg || Vue.translations.get('common.too_short');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (String(v).length >= minValue) ? true : msg;
    }
  }

  // Numero maximo de caracteres
  maxChars(maxValue = 30, msg) {
    msg = msg || Vue.translations.get('common.too_large');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (String(v).length <= maxValue) ? true : msg;
    }
  }

  // Comprueba si un Object o un Array tiene un numero minimo de elementos
  minItems(minValue = 1, msg) {
    msg = msg || Vue.translations.get('common.too_few_items');
    return (v) => {
      let len = 0;
      if (isArray(v)) {
        len = v.length;
      } else if (isObject(v)) {
        len = length(v);
      }
      return (len >= minValue) ? true : msg;
    }
  }

  // Compruebo si es un numero entero positivo
  isPositiveInt(msg) {
    msg = msg || Vue.translations.get('common.must_be_positive_number');
    let re = /^\d+$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  // Compruebo si es un numero entero (positivo o negativo)
  isInt(msg) {
    msg = msg || Vue.translations.get('common.must_be_int');
    let re = /^-?\d+$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  // Compruebo si es un numero flotante (positivo o negativo)
  isFloat(msg) {
    msg = msg || Vue.translations.get('common.must_be_number');
    let re = /^-?\d*([\.\,]?\d+)?/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  // Compruebo si es un numero (entero o flotante) positivo
  isNumber(msg) {
    msg = msg || Vue.translations.get('common.must_be_number');
    //let re = /^\d*(\.\d+)?$/;
    let re = /^\d*([\.\,]?\d+)?$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  isPhone(msg) {
    msg = msg || Vue.translations.get('common.phone_invalid', 'número de teléfono invalido');
    const regExp = /^\+?(\d{1,4})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (regExp.test(String(v))) ? true : msg;
    }
  }

  isCIF(msg) {
    msg = msg || Vue.translations.get('common.cif_invalid', 'cif invalido');
    const regExp = /^[ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (regExp.test(String(v))) ? true : msg;
    }
  }

  isNIFOrCIF(msg){
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v) =>{
      return isCIF(v) || isNIF(v) ? true : msg;
    }
  }

  // Numero mayor o igual que un valor
  minValue(value = 0, msg) {
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (this.isInt(v) && Number(v) >= Number(value)) ? true : msg;
    }
  }

  // Numero mayor o igual que un valor
  minValueOptional(value = 0, required = false, msg) {
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      if (!required) {
        return true
      }
      return (this.isInt(v) && Number(v) >= Number(value)) ? true : msg;
    }
  }

  // Numero menor o igual que un valor
  maxValue(value = 100, msg) {
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (this.isInt(v) && Number(v) <= Number(value)) ? true : msg;
    }
  }

  // Comprueba si es una cadena de texto, incluso aunque sea un numero entrecomillado (false)
  isString(msg) {
    msg = msg || Vue.translations.get('common.must_be_string');
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (isNaN(Number(v))) ? true : msg;
    }
  }

  // Comprueba si es un email valido. Opcionalmente se pueden comprobar un listado de emails separados por comas
  isEmail(msg, multiple = false) {
    msg = msg || Vue.translations.get('common.wrong_mail');
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (multiple) {
      re = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})),?\s?)+$/;
    }
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  isUrl(msg) {
    msg = msg || Vue.translations.get('common.wrong_url');
    let re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  isSlug(msg) {
    msg = msg || Vue.translations.get('common.wrong_slug');
    let re = /^[\w-]*$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  // Comprueba que el valor del campo es igual a un valor de referencia
  matchField(referenceValue, msg) {
    msg = msg || Vue.translations.get('common.must_match');
    return (v) => {
      if (isNull(v)) {
        return true;
      }
      if (isEmptyString(referenceValue) || isNull(referenceValue)) {
        return msg;
      }
      return (v === referenceValue) ? true : msg;
    }
  }

  // Una cadena de texto sin espacios en blanco
  noBlankSpace(msg) {
    msg = msg || Vue.translations.get('common.no_blank_spaces');
    let re = /\s/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (!re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  isIpAddress(msg) {
    msg = msg || Vue.translations.get('common.wrong_ip');
    let re = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
    return (v) => {
      if (isNull(v) || isEmptyString(v)) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }

  custom(func, msg) {
    return (v) => {
      return func(v)
    }
  }

  haveScript(msg){
    msg = msg || Vue.translations.get('common.need_script_in_end','Es necesario incluir - en el final');
    const res = new RegExp("-$")
    return (v) =>{
      return res.test(v) ? true : msg
    }
  }

  validateString(msg){
    msg = msg || Vue.translations.get('common.need_valid_string','Cadena de texto no valida');
    const res = /[^0-9A-Za-zñÑáÁàÀéÉíÍïÏóÓòÒúÚüÜçÇ°ºª.,()¿?¡!+ ]/g
    return (v) =>{
      return !res.test(v) ? true : msg
    }
  }

  // NIF y NIE
  /*isCif(msg) {
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v)=> {
      if(isNull(v) || isEmptyString(v)){return true;}
      return (validateNifNie(String(v).toLowerCase())) ? true : msg;
    }
  }
  // CIF
  isNif(msg) {
    let re = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
    msg = msg || Vue.translations.get('common.invalid_value');
    return (v)=> {
      if(isNull(v) || isEmptyString(v)){return true;}
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }*/
}


// Valida la formula oficial del Nif espaniol
/*function validateNifNie(value, elemID) {
	var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
	var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
	var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
	var str = value.toString().toUpperCase();

	if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

	var nie = str
	.replace(/^[X]/, '0')
	.replace(/^[Y]/, '1')
	.replace(/^[Z]/, '2');

	var letter = str.substr(-1);
	var charIndex = parseInt(nie.substr(0, 8)) % 23;

	if (validChars.charAt(charIndex) === letter) return true;

	return false;
}*/

export default Plugin;
