import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@/sass/styles.scss';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "@/router";
import ValidationRules from "@/scripts/ValidationRules";
import Translations from "@/scripts/Translations";
import store from "@/store";

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

Vue.use(VueAxios, axios)

Vue.use(ValidationRules);

Vue.use(Translations);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
