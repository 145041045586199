import store from "@/store";

export default function (to, from, next) {
  if(to.name === 'invoice'){
    const can_make_invoice = store.getters['getCanMakeInvoice'] || false
    if(can_make_invoice) next()
  }else{
    next()
  }
};
