import {isArray, isEmptyArray, toArray} from '@/scripts/helpers/ArrayHelpers';
import {isEmptyString, isString, trim} from '@/scripts/helpers/StringHelpers';
import {length as jsonLength} from '@/scripts/helpers/JsonHelpers';
import {clone as _clone, extend as _extend, isEqual as _isEqual, merge as _merge} from 'lodash';

// Comprueba si es un objeto puro (no un Array)
export function isObject(obj) {
  return (typeof obj === 'object' && !isArray(obj) && !isNull(obj)) ? true : false;
}

// Longitud de un objeto (no falla aunque sea un Array)
export function length(obj) {
  return isArray(obj) ? obj.length : jsonLength(obj);
}

// Devuelve True si es un objeto vacio ({}) y false en el resto de casos
export function isEmptyObject(obj) {
  return (isObject(obj) && isEqual(obj, {})) ? true : false;
}

// Devuelve true si es null, undefined...
export function isNull(value) {
  return (value === null || value === undefined || value === 'null' || value === 'undefined') ? true : false;
}

// Convierte a null los undefined. El resto de valores los devuelve tal cual
export function undefinedToNull(value) {
  return isNull(value) ? null : value;
}

// Convierte a null los false. El resto de valores los devuelve tal cual
export function falseToNull(value) {
  return (value === false) ? null : value;
}

// Comprueba si en un objeto existe una propiedad (o cadena de propiedades)
// A diferencia del metodo nativo hasOwnProperty, este evalua la cadena completa, y da valores mas coherentes
// Por defecto solo comprueba si la propiedad existe (su path), si se especifica validValues comprueba si es un valor no null o no undefined
// hasProperty(this, 'my.sub.object.variable')
export function hasProperty(obj, prop, validValues = false) {
  if (validValues) {
    try {
      let result = eval('obj.' + prop);
      if (!isNull(result)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  try {
    let result = eval('obj.' + prop);
    if (result !== undefined) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

// Comprueba si en un objeto existen una serie de propiedades (array)
export function hasProperties(obj, props = []) {
  return props.reduce((acc, p) => hasProperty(obj, p, true) && acc, (obj ? true : false));
}

// Devuelve una propiedad en un objeto, o un valor por defecto
export function getProperty(obj, prop, defaultValue = undefined) {
  try {
    var result = eval('obj.' + prop);
    if (!isNull(result)) {
      return result;
    } else {
      return defaultValue;
    }
  } catch (error) {
    return defaultValue;
  }
}

// Clona un Array de forma profunda, sin ninguna referencia a los objetos interiores que pudiera contener
// Se puede especificar un valor por defecto en caso que el objeto sea null o undefined
export function clon(obj, defaultValue = null) {
  if (isNull(obj)) {
    return defaultValue;
  }
  return JSON.parse(JSON.stringify(obj));
}

export function deepClon(obj, defaultValue = null) {
  if (isNull(obj)) {
    return defaultValue;
  }
  return _clone(obj);
}

// Compara dos Objetos
export function isEqual(obj1, obj2) {
  return _isEqual(obj1, obj2);
}

// Une dos objetos
export function join(obj1, obj2, obj3) {
  return _extend({}, obj1, obj2, obj3);
}

//console.log('join: ', join({name: 'Iván', props: {age: 42}}, {props: {height: 178}}));

// Mezcla dos objetos
// Hace una copia profunda, respeta las propiedades del objeto original y solo sustituye o aniade las nuevas
export function merge(target, source) {
  return _merge(target, source);
}

//console.log('merge: ', merge({name: 'Iván', props: {age: 42}}, {props: {height: 178}}));

// Dado un objeto elimina los valores false, null y undefined o un string vacio o un array vacio o un objeto vacio
// Devuelve un objeto nuevo sin referencias al anterior
export function clean(obj) {
  return removeInvalidValues(removeFalseValues(obj));
}

// Dado un objeto elimina los valores iguales a null o undefined o un string vacio o un array vacio o un objeto vacio
// Devuelve un objeto nuevo sin referencias al anterior
export function removeInvalidValues(obj) {
  if (isNull(obj)) {
    return null;
  }
  const clone = clon(obj);
  let isA = isArray(clone);
  let result = (isA) ? [] : {};
  let fn = (isA) ? appendToArray : updateObjectValue;
  let item;

  // Itero por objeto original, pero hago cambios en el clon, para que el bucle no se vuelva loco
  for (var o in clone) {
    item = clone[o];
    // Objeto con mas objetos dentro. Lo tengo que filtrar antes de aceptarlo como valido
    if (length(item) > 0) {
      const oTemp = removeInvalidValues(clone[o]);
      if (!isEmptyArray(oTemp) && !isEmptyObject(oTemp)) {
        fn(result, oTemp, o);
      }
    } else {
      // Elimino los espacios en blanco de los extremos
      item = (isString(item)) ? trim(item) : item;
      // Nodo final. Si no es nulll, lo devuelvo como valido
      if (!isNull(item) && !isEmptyString(item) && !isEmptyArray(item) && !isEmptyObject(item)) {
        fn(result, item, o);
      }
    }
  }
  return result;
}

function appendToArray(obj, newValue) {
  obj.push(newValue);
}

function updateObjectValue(obj, newValue, key) {
  obj[key] = newValue;
}

//console.log('removeInvalidValues(): ', removeInvalidValues([{"extraprice":0,"name":"taza"},{"extraprice":0.5,"name":"vaso grande"},{"extraprice":1,"name":"jarra"}]));
//console.log('removeInvalidValues: ', removeInvalidValues([null, {"id":"-Ke5cas5iIBoLGeGce_z","name":null}, {"id":"-Ke56AiZxcMh18P7GD1F","name":"alubias tolosa"}]));

// Dado un objeto elimina los valores iguales a False
// Devuelve un objeto nuevo sin referencias al anterior
export function removeFalseValues(obj) {
  const clone = clon(obj);
  for (var o in clone) {
    // Tiene que ir en este orden para que funcione la recursividad bien
    if (length(clone[o]) > 0) {
      clone[o] = removeFalseValues(clone[o]);
    }
    if (clone[o] === false) {
      delete clone[o];
    }
  }
  return clone;
}

//console.log('removeFalseValues(): ', removeFalseValues({"extraprice":false,"name":"taza", props:{"isName":false, "array":[{"foo":false}]}}));

// Dado un objeto de objetos con propiedades, devuelve un objeto que tenga solo las propiedades especificadas
// keys puede ser un String o Array de strings
// Devuelve un objeto
export function getOnlySomeKeys(obj, keys) {
  if (!isObject(obj) || isNull(keys)) {
    return {};
  }
  keys = toArray(keys);
  let temp = {};
  keys.forEach((keyName) => {
    if (hasProperty(obj, String(keyName))) {
      temp[keyName] = obj[keyName];
    }
  })
  return temp;
}

//console.log(find({{name:'Iván'}}, name, 'Iván'));

// Dado un objeto devuelve sus claves como un Array
export function getKeys(obj) {
  if (!obj) {
    return [];
  }
  return Object.keys(obj || []);
}

// Dado un objeto devuelve sus valores como un Array
export function getValues(obj) {
  if (!obj) {
    return [];
  }
  return Object.values(obj || []);
}

// Dado un objeto devuelve el id de su primer elemento
export function getFirstKey(obj) {
  if (!obj) {
    return null;
  }
  let keys = getKeys(obj);
  return keys.length ? keys[0] : null;
}

// Dado un objeto devuelve su primer elemento
export function getFirstValue(obj) {
  if (!obj) {
    return null;
  }
  let values = getValues(obj);
  return values.length ? values[0] : null;
}

// elimina y devuelve el valor de una propiedad
// modifica el objeto original
// Si el objeto no tiene esa propiedad, el objeto no se modifica y la funcion devuelve null
// extract({name:'Iván', surname:'gajate', props:{age:42}}, 'props.age') => '42 (y deja el objeto como {name:'Iván', surname:'gajate'})
export function extract(obj, path) {
  if (!obj) {
    return null;
  }
  if (!isString(path)) {
    return null;
  }

  path = path.split('/').join('.');
  let result = null;
  try {
    const value = eval('obj.' + path);
    if (value !== undefined) {
      result = value;
      eval('delete obj.' + path);
    }
  } catch (error) {
    //
  }
  return result;
}

//console.log('extract: ', extract({name:'Iván', surname:'gajate', props:{age:42}}, 'props.age'));

// Elimina las propiedades de un objeto
// props es un String o un Array de strings
// Modifica el objeto original
// deleteProps({name:'Iván', surname:'gajate', age:42}, ['name', 'age']) => Objeto original = {surname:'gajate'}
export function deleteProps(obj, props) {
  if (!obj) {
    return;
  }
  props = toArray(props);
  props.forEach(p => {
    try {
      eval('delete obj.' + p);
    } catch (error) {
      //
    }
  });
}

//let obj = {name:'Iván', surname:'gajate', age:42};
//deleteProps(obj, ['name', 'props.age']);
//console.log('obj: ', obj);

//let obj = {name:'Iván', surname:'gajate', skills:{name:'bike', value:true}};
//console.log('extract(): ', extract(obj, 'skills/name'), obj);

// Dado un path (nodo1/nodo2/nodo3...) devuelve un objeto vacio con esa estructura ({nodo1:{nodo2:{nodo3:{}}}})
// Se le puede pasar un Data para usar como valor del objeto mas profundo, de lo contrario se usa un objeto vacio
export function pathToObject(path, data = {}) {
  if (!isString(path)) {
    return {};
  }
  let arr = path.split('/');
  if (arr.length > 1) {
    let id = arr.shift();
    let value = pathToObject(arr.join('/'), data);
    let temp = newObject(id, value);
    return temp;
  } else {
    return newObject(arr[0], data);
  }
}

//console.log('pathToObject(): ', pathToObject('nodo1/nodo2/nodo3/name', 'ivan'));

// Equivalente al Array.map pero en Object
// Ejecuta una funcion con cada item del objeto, pasandole como parametros (itemValue, itemKey, json)
export function mapObject(json, fn) {
  if (!json) {
    return {};
  }
  if (!fn) {
    return json;
  }
  let result = {};
  for (var i in json) {
    result[i] = fn(json[i], i, json);
  }
  return result;
}

/*console.log('mapObject(): ', mapObject({'12345':{name:'cocacola'}, '23451':{name:'agua'}, '34567':{name:'aquarius'}}, (item)=>{
	//item['foo'] = Math.random();
	item['foo'] = Math.random();
	return item;
}));*/


// Equivalente al Array.forEach pero en Object
// Ejecuta una funcion con cada item del objeto, pasandole como parametros (itemValue, itemKey, json)
export function forEachObject(json, fn) {
  if (!json || !fn) {
    return;
  }
  for (var i in json) {
    fn(json[i], i, json);
  }
}

/*console.log('forEachObject(): ', forEachObject({'12345':{name:'cocacola'}, '23451':{name:'agua'}, '34567':{name:'aquarius'}}, (currentValue, index, json)=>{
	console.log('currentValue, index, json: ', currentValue, index, json);
}));*/


// Equivalente al Array.filter pero en Object
// Ejecuta una funcion con cada item del objeto, pasandole como parametros (itemValue, itemKey, json)
export function filterObject(json, fn) {
  if (!json) {
    return {};
  }
  if (!fn) {
    return json;
  }
  let result = {};
  for (const i in json) {
    if (fn(json[i], i, json)) {
      result[i] = json[i];
    }
  }
  return result;
}

/* console.log('filterObject(): ', filterObject({'12345':{name:'cocacola'}, '23451':{name:'agua'}, '34567':{name:'aquarius'}}, (currentValue, index, json)=>{
	return index === '23451';
})); */

// rename an object key
// renameKey({business: '123456'}, 'business', 'local')
// Devuelve {local: '123456'}
export function renameKey(obj, key, newKey) {
  if (!obj) {
    return {};
  }
  if (!key || !newKey) {
    return obj;
  }
  let result = {
    ...obj,
    [newKey]: obj[key]
  };
  delete result[key];
  result = removeInvalidValues(result);
  return result;
}

//console.log(renameKey({business: '123456'}, 'business', 'local'));

export function getChangeProps(obj, obj2){
  let history_arr = [];
  for(let key in obj){
    if(obj[key] !== null) {
      let history_obj = {};
      let found_key = false;
      for (let key2 in obj2) {
        if (key === key2) {
          found_key = true;
          if ((obj[key] instanceof Array && obj2[key2] instanceof Array) || (obj[key] instanceof Object && obj2[key2] instanceof Object)) {
            history_arr.push(...getChangeProps(obj[key], obj2[key2]))
          } else {
            if (obj[key] !== null && obj2[key2] !== null && obj[key] !== obj2[key2]){
              history_obj[key] = {
                new:obj[key],
                old:obj2[key2]
              };
            }
          }
        }
      }
      if (!found_key){
        history_obj[key] = {
          old:'-',
          new:obj[key]
        };
      }
      if (!isEmptyObject(history_obj)) history_arr.push(history_obj);
    }
  }
  return history_arr;
}

export function recursiveAddNotFoundProperties(obj1, obj2, add_new_property_mark = false){
  for(let key in obj1) {
    if (obj1[key] instanceof Array || obj1[key] instanceof Object) {
      obj2[key] = recursiveAddNotFoundProperties(obj1[key], obj2[key] || {}, add_new_property_mark);
    } else {
      const obj2_keys = Object.keys(obj2);
      if (!obj2_keys.find(item => item === key)) {
        obj2[key] = add_new_property_mark ? `**${obj1[key]}` : obj1[key];
      }
    }
  }
  return obj2;
}
