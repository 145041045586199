import Vuex from "vuex";
import invoiceMutation from "@/store/invoice/mutations";
import invoiceGetter from "@/store/invoice/getters";
import Vue from "vue";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        invoice:{
            mutations:invoiceMutation,
            getters:invoiceGetter
        }
    }
});

export default store
