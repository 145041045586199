import {isString, toLowerCase, randomId} from '@/scripts/helpers/StringHelpers';
import {isNull, hasProperty, removeInvalidValues, isEqual as objectIsEqual} from '@/scripts/helpers/ObjectHelpers';
import {isBoolean} from '@/scripts/helpers/BooleanHelpers';
import {
  isEqual as _isEqual,
  difference as _difference,
  intersection as _intersection,
  uniq as _uniq,
  uniqWith as _uniqWith,
  pullAll as _pullAll
} from 'lodash';

export function isArray(arr) {
  return (Array.isArray(arr)) ? true : false;
}

// Devuelve True si es un Array vacio, y false en el resto de los casos
export function isEmptyArray(arr) {
  return (isArray(arr) && arr.length === 0) ? true : false;
}

export function clon(arr) {
  if (!arr) {
    return null;
  }
  return JSON.parse(JSON.stringify(arr));
}

// Compara dos Arrays
export function isEqual(arr1, arr2) {
  if (!isArray(arr1) || !isArray(arr2)) {
    return false;
  }
  return _isEqual(arr1, arr2);
}


// Dado un array de objetos, busca un objeto que tenga el key especificado.
// Devuelve el Objeto encontrado
// Si no lo encuentra devuelve null
// No es sensible a las mayusculas
export function find(arr, key, value, sensitive = false) {
  if (!isArray(arr) || !isString(key)) {
    return null;
  }
  if (!sensitive) {
    return arr.find((item) => toLowerCase(item[key]) === toLowerCase(value)) || null
  } else {
    return arr.find((item) => item[key] === value) || null
  }
}

// Dado un array de objetos, busca un objeto con un key similar al value (indexOf) y devuelve el array de resultados
// No es sensible a las mayusculas
export function search(arr, key, value) {
  if (!isArray(arr) || !isString(key)) {
    return [];
  }
  var result = arr.filter((item) => {
    return (toLowerCase(item[key]).indexOf(toLowerCase(value)) > -1) ? true : false;
  });
  return result;
}


// Dado un array de objetos, busca un objeto que tenga el key especificado
// No es sensible a las mayusculas
// Devuelve el indice del primero que encuentre (o -1 si no lo encuentra)
export function indexWithKeyValue(arr, key, value) {
  if (!isArray(arr) || !isString(key)) {
    return -1;
  }
  var result = arr.findIndex((item) => {
    return toLowerCase(item[key]) === toLowerCase(value);
  });
  return result;
}


// Dado un array de objetos, devuelve solo los elementos que posean una propiedad
// mantiene vivos los objetos
// Devuelve un array
export function getItemsWithKey(arr, key) {
  if (!isArray(arr)) {
    return [];
  }
  if (!isString(key)) {
    return [];
  }
  let result = arr.filter((item) => {
    return hasProperty(item, key);
  });
  return result;
}

//console.log('getItemsWithKey(): ', getItemsWithKey([{id:'-Ke56AiZxcMh18P7GD1F', name:'iván'}, {id:'1254875445', value:54}], 'value'));


// Daado un array de objetos, busca un objeto que sea exactamente igual al especificado
// Devuelve el indice del primero que encuentre (o -1 si no lo encuentra)
export function indexOfEqual(arr, obj) {
  if (!isArray(arr)) {
    return -1;
  }
  let result = arr.findIndex((item) => {
    return _isEqual(item, obj);
  });
  return result;
}

//console.log('indexOfEqual(): ', indexOfEqual([{name:'Iván', value:34}], {value: 34, name:'Iván'}));

// Dado un array de objetos, borra un elemento que su key===value y devuelve el array
/*export function deleteItem(arr, key, value){
	if(!isArray(arr) || !isString(key)) {return [];}
	var result = arr.filter(function(item){
		return item[key] !== value;
	});
	return result;
};*/

//console.log('deleteItem(): ', deleteItem([{id:'-Ke56AiZxcMh18P7GD1F'}], 'id', '-Ke56AiZxcMh18P7GD1F'));

// Dado un array de objetos
// Devuelve un Array con los valores de key
export function getKeyValues(arr, key) {
  if (!isArray(arr) || !isString(key)) {
    return []
  }
  var result = [];
  arr.forEach((item) => {
    if (hasProperty(item, key)) {
      result.push(item[key]);
    }
  });
  return result;
}

//console.log('getKeyValues(): ', getKeyValues([{id:'-Ke56AiZxcMh18P7GD1F', name:'iván'}, {id:'1254875445', value:54}], 'name'));  // ["iván"]

// Dado un array de objetos [{...}, {...}, {...}] y un nuevo array de ordenes [1, 0, 2], devuelvo un array (de referencias) con los objetos reordenados
export function reorder(arr, newOrder) {
  if (!isArray(arr) || !isArray(newOrder)) {
    return [];
  }
  if (arr.length !== newOrder.length) {
    return [];
  }
  var result = [];
  newOrder.forEach((newIndex) => {
    if (arr[newIndex]) {
      result.push(arr[newIndex]);
    }
  });
  if (result.length !== newOrder.length) {
    return [];
  }
  return result;
}

// Desordeno un array
export function shuffle(a) {
  var o = a.slice(0);
  for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) ;
  return o;
}

// Dado un array de numeros, los ordena numericamente, no alfabeticamente
export function sortNumeric(arr) {
  if (!isArray(arr)) {
    return [];
  }
  if (!arr.length) {
    return arr;
  }
  return arr.sort((a, b) => (Number(a) - Number(b)));
}

// Dado un array de objetos los ordena segun un nombre de clave
export function sortByKey(arr, keyName, reverse, numeric = false) {
  if (!isArray(arr) || !isString(keyName)) {
    return [];
  }
  if (!arr.length) {
    return arr;
  }
  if (!isBoolean(reverse)) {
    reverse = false;
  }
  let trueValue = 1;
  let falseValue = -1;
  if (reverse) {
    trueValue = -trueValue;
    falseValue = -falseValue;
  }
  const sortAlphabetically = (a, b) => (toLowerCase(a[keyName]) > toLowerCase(b[keyName])) ? trueValue : falseValue;
  const sortNumeric = (a, b) => (Number(a[keyName]) > Number(b[keyName])) ? trueValue : falseValue;
  const compareFn = numeric ? sortNumeric : sortAlphabetically;
  return arr.sort(compareFn);
}

//console.log('sortByKey([1,2,5], ): ', sortByKey([{year:'2018'}, {year:'2017'}, {year:'2019'}], 'year', false, false));


// Dado un array de objetos los ordena por un nombre de clave,
// pero ignorando cierta parte del keyName ('TC-144' solo tiene en cuenta el 144)
// convierte los valores parciales a Number para compararlos
// Devuelve un array
export function sortByPartialKey(arr, keyName, prefix, reverse) {
  if (!isArray(arr) || !isString(keyName)) {
    return [];
  }
  if (!arr.length) {
    return arr;
  }
  if (!isBoolean(reverse)) {
    reverse = false;
  }
  let trueValue = 1;
  let falseValue = -1;
  if (reverse) {
    trueValue = -trueValue;
    falseValue = -falseValue;
  }
  // Ordena 'TC-144' solo por el valor numerico 144
  const truncateValueToNum = value => Number(value.replace(prefix, ''));
  return arr.sort((a, b) => {
    return (truncateValueToNum(a[keyName]) > truncateValueToNum(b[keyName])) ? trueValue : falseValue;
  });
}

//console.log('sortByPartialKey([1,2,5], ): ', sortByPartialKey([{year:'TC-144'}, {year:'TC-15'}, {year:'TC-145'}, {year:'TC-151'}], 'year', 'TC-', true));

// Convierte un objeto en un Array (lo devuelve como primer elemento de un array de un elemento)
// Si ya es un Array lo devuelve tal cual
export function toArray(obj) {
  if (isNull(obj)) {
    return [];
  }
  if (isArray(obj)) {
    return obj;
  }
  return [obj];
}

// Devuelve los valores de Arr que no aparezcan en Others
// "resta" Others de Arr
// difference([1, 2, 3, 4, 5], [5, 2, 10]) => [1, 3, 4]
export function difference(arr, others) {
  if (!isArray(arr) || !isArray(others)) {
    return [];
  }
  return _difference(arr, others);
}

// Devuelve los valores de Arr que aparezcan en Others
// "intersecta" Others de Arr, los elementos comunes
// intersection([1, 2, 3, 4, 5], [5, 2, 10]) => [2, 5]
export function intersection(arr, others) {
  if (!isArray(arr) || !isArray(others)) {
    return [];
  }
  return _intersection(arr, others);
}

//console.log('intersection: ', intersection([1, 2, 3, 4, 5], [5, 2, 10]));

// Devuelve un Array sin objetos repetidos
export function uniq(arr) {
  if (!isArray(arr)) {
    return [];
  }
  return _uniq(arr);
}

// Dado un Array de objetos, devuelve un Array sin objetos repetidos dado un key especifico
export function uniqKey(arr, key) {
  if (!isArray(arr)) {
    return [];
  }
  if (isNull(key)) {
    return arr;
  }
  return _uniqWith(arr, (val1, val2) => {
    return objectIsEqual(val1[key], val2[key]);
  })
}

//console.log('uniq(): ', uniq(['foo', 'foo', 'bar']));

// Elimina un elemento o array de elementos de un array
// hace una copia y la devuelve
export function remove(arr, value) {
  if (!isArray(arr)) {
    return [];
  }
  if (isNull(value)) {
    return arr;
  }
  value = toArray(value);
  return _pullAll([...arr], value);
}

// Convierte un array de objetos, en un objeto con un valor especificado
// arrayToObject([{name:'CocaCola', price:2, id:'1234567'}, ...], 'id')  => {'1234567': {name:'CocaCola', price:2}, ...}
// Si algun objeto no contiene la propiedad devuelve un objeto con una Id aleatoria
// Si no se especifica ningun keyName todos los objetos tendran una Id aleatoria
// No mantiene vivas las referencias a los objetos y arrays interiores, hace un clon
// Devuelve un objeto
export function arrayToObject(arr, keyName) {
  if (!isArray(arr)) {
    return {};
  }
  let temp = {};
  arr.forEach((item) => {
    let value = (item[keyName]) ? item[keyName] : null;
    if (!isNull(value)) {
      temp[value] = clon(item);
      delete temp[value][keyName]
    } else {
      temp[randomId('ID_')] = clon(item);
    }
  })
  return temp;
}

//console.log('arrayToObject(): ', arrayToObject([{name:'CocaCola', price:2, id:'1234567'}, {name:'Agua', price:1}], 'id'));


// Convierte un array de strings en un objeto con propiedades igual a un valor (true por defecto)
// ['foo', 'bar'] => {foo:true, bar:true}
export function simpleArrayToObject(arr, value) {
  if (!isArray(arr)) {
    return {};
  }
  if (isNull(value)) {
    value = true;
  }
  let temp = {};
  for (var i in arr) {
    temp[arr[i]] = value;
  }
  return temp;
}


// Dado un Array de objetos, extrae el primero que tenga un key y un value (opcional)
// Devuelve el elemento extraido
// Mantiene vivo el array original
// Si no encuentra el objeto devuelve Null
export function extract(arr, key, value) {
  if (isNull(arr)) {
    return null;
  }
  if (isNull(key)) {
    return null;
  }

  let index = indexWithKeyValue(arr, key, value);
  if (index > -1) {
    return arr.splice(index, 1)[0];
  }
  return null;
}

//console.log('extract:', extract([{id:'12345', name:'Paco'}, {id:'23451', name:'Manolo'}], 'id', '12345')) // {id: "12345", name: "Paco"}


// Dado un Array de objetos, sustituye el Key de cada uno de sus objetos por uno nuevo
// Devuelve un Array
export function switchKeyName(arr, oldKey, newKey) {
  if (isNull(arr) || !isArray(arr)) {
    return null;
  }
  arr = clon(arr);
  if (isNull(oldKey) || isNull(newKey)) {
    return arr;
  }
  if (oldKey === newKey) {
    return arr;
  }
  let result = arr.map((item) => {
    if (hasProperty(item, oldKey)) {
      item[newKey] = item[oldKey];
      delete item[oldKey];
    }
    return item;
  });
  return result;
}

//console.log('switchKeyName(): ', switchKeyName([{id:'123456', name:'iván'}, {id:'234566', surname:'garcía'}], 'id', 'key'));


// Dado un Array de objetos, devuelve un array de objetos solo con los Keys especificados (hace una limpia de propiedades)
// Devuelve un Array
export function getOnlySomeKeys(arr, keys) {
  if (isNull(arr)) {
    return null;
  }
  arr = clon(arr);
  if (isNull(keys)) {
    return arr;
  }
  keys = toArray(keys);
  let result = arr.map((item) => {
    let temp = {};
    keys.map((k) => {
      if (hasProperty(item, k)) {
        temp[k] = item[k];
      }
    })
    return temp;
  });
  result = removeInvalidValues(result);
  return result;
}

//console.log('getOnlySomeKeys(): ', getOnlySomeKeys([{id:'123456', name:'iván'}, {id:'234566', surname:'garcía'}], ['id', 'name']));

// Dado un array devuelve los ultimos N elementos
// Siempre devuelve un array, aunque sea vacio
// Devuelve un clon del array original, pero no mantiene las referencias
export function getLastNElements(arr = [], n = 0) {
  if (!isArray(arr)) {
    return [];
  }
  let clone = clon(arr);
  return clone.slice(Math.max(clone.length - n, 0));
}

//console.log('getLastNElements([1,2,3,4,5,6,7], 2): ', getLastNElements([1,2,3,4,5,6,7], 2));

// Dado un array de numeros (enteros positivos) devuelve el primer numero no utilizado
// Ej: Un array de IDs, obtener el primer ID libre
/*export function getFirstNumValue(arr, first=0){
	if(!isArray(arr)){return first;}
	let clone = clon(arr);
	clone.sort();
	clone.map((item)=>{

	})
}
console.log(getFirstNumValue([1,5,2]));*/


