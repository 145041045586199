export const c_white = '#ffffff'; // Blanco del fondo del contenido (Light)
export const c_shark = '#22282a'; // Gris oscuro del del fondo del contenido (Dark)
export const c_turquoise = '#21D8F6'; // Azul turquesa destacado (Light y Dark)
export const c_order_printed = '#EA9502';
export const c_falu_red = '#901313'; // Rojo de los textfield con error (Dark)
export const c_oxford_blue = '#062A40'; // Gris verdoso del fondo del sidebar (Light)
export const c_cinder = '#1d2324'; // Gris verdoso del fondo del sidebar (Dark)
export const c_san_marino = '#4c7897'; // Gris intermedio del texto del sidebar (Light)
export const c_traut = '#474e53'; // Gris intermedio del texto del sidebar (Dark)
export const c_solitude = '#F2F7F8'; // Gris claro del fondo de la barra de titulo (Light)
export const c_cinder_2 = '#1f2527'; // Gris oscuro del fondo de la barra de titulo (Dark)
export const c_blue_bayoux = '#5c6a78'; // Gris azulado del texto de todos los fields (Light)
export const c_silver = '#b7b7b7'; // Gris claro del texto de todos los fields (Dark)
export const c_gainsboro = '#d8d8d8'; // Gris claro del borde del la barra de titulo (Light)
export const c_charade = '#404547'; // Gris claro del borde del la barra de titulo (Dark)
export const c_fire_brick = '#ae1e1e'; // Rojo de los textfield con error (Light)
export const c_circle_status_accepted = '#FFEB00';
export const c_circle_status_preparation = '#FA9E00';
export const c_circle_status_ready = '#2DB682';
export const c_circle_status_delivery = '#037598';
export const c_dark_red = '#9E2020';
export const c_light_blue = '#094163';
export const c_dark_orange = '#AD720C';
export const c_dark = '#000000';

let bkp = {
    'primary': '#1976D2',
    'secondary': '#424242',
    'accent': '#82B1FF',
    'error': '#FF5252',
    'info': '#2196F3',
    'success': '#4CAF50',
    'warning': '#FB8C00'
};

export const theme_light = {
    ...bkp,
    ...{
        primary: c_turquoise,
        secondary: c_fire_brick,
        accent: c_turquoise,
        error: c_fire_brick,
        printed: c_order_printed,
        sidebar: c_oxford_blue,
        sidebarText: c_san_marino,
        toolbar: c_solitude,
        toolbarText: c_blue_bayoux,
        toolbarBorder: c_gainsboro,
        mainContentBg: c_white,
        circleAccepted: c_circle_status_accepted,
        circlePreparation: c_circle_status_preparation,
        circleReady: c_circle_status_ready,
        circleDelivery: c_circle_status_delivery,
        darkRed: c_dark_red,
        lightBlue: c_light_blue,
        darkOrange: c_dark_orange,
        dark: c_dark
    }

}
