import {forEachObject} from '@/scripts/helpers/ObjectHelpers';
import axios from "axios";
import Vue from "vue";

var instance;

const Plugin = {
  install: function (Vue) {
    // Makes sure that plugin can be installed only once
    if (this.installed) {
      return;
    }
    this.installed = true;

    instance = new Translations();

    // 1. add global method or property
    Vue.translations = instance;

    // 4. add an instance method
    Vue.prototype.$translations = instance;
  }
};


class Translations {
  constructor() {
    this.code = 'es_ES';
    this.json = {};
  }

  setup() {
    return instance.loadTranslations();
  }

  get(path, defaultValue = '', splitParams) {
    // Acepto rutas con "/" y con "."
    path = path.split('/').join('.');
    try {
      let splitted = '["' + path.split('.').join('"]["') + '"]';
      let result = eval('this.json' + splitted);
      if (result && typeof result === 'string') {
        return this.translateParams(result, splitParams);
      }
    } catch (error) {
      //console.log('error: ', error);
    }
    if (defaultValue!=='') this.set(path, defaultValue)
    if (defaultValue) return defaultValue
    return path;
  }

  async set(path, defaultValue) {
    try {
      const lang = 'es_ES'
      path = `config_requested/translations/admin/${lang}/` + path.split('.').join('/');
      await this.$http.post(`/v2/translations/admin?lang=es-ES&path_translation=${path.split('.').join('/')}&value_translation=${defaultValue}`)
    } catch (e) {
    }
  }

  setLang(newCode) {
    this.code = newCode;
    return instance.loadTranslations();
  }

  // "es_ES"
  getCode() {
    return this.code;
  }

  // "es"
  getLang() {
    let lang = this.code.split('_');
    lang = (lang.length) ? lang[0] : null;
    return lang;
  }

  loadTranslations() {
    return new Promise(async (resolve, reject) => {
      this.json = (await Vue.prototype.$http.get(`/v2/translations/admin?lang=es-ES`)).data
    });
  };

  // si la traduccion contiene variables ${keyName} que hay que sustituir con el formato {keyName: 'valor'}
  translateParams(str, splitParams) {
    if (!splitParams) {
      return str
    }
    forEachObject(splitParams, (value, key) => {
      str = str.split('${' + key + '}').join(value);
    });
    return str;
  }
}


export default Plugin;
