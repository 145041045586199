
import Router from 'vue-router';
import Vue from "vue";
import routeAccess from "./middlewares/route_access";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:token',
      name: 'home',
      component: () => import(/* webpackPrefetch: true */'@/components/MainView.vue')
    },
    {
      path: '/invoice/:token',
      name: 'invoice',
      component: () => import(/* webpackPrefetch: true */'@/components/MakeInvoiceView.vue')
    },
  ]})

router.beforeEach(routeAccess);

export default router;
