<template>
  <v-app>
    <v-main class="mainContentBg application">
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import {theme_light} from "@/scripts/themes";

export default {
  mounted() {
    this.$vuetify.theme.themes.light = theme_light;
    this.$vuetify.theme.themes.dark = theme_light;
    this.$translations.setLang("es_ES");
  }
}
</script>
