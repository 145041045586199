import {isObject, isNull} from '@/scripts/helpers/ObjectHelpers';
import {isArray} from '@/scripts/helpers/ArrayHelpers';

// Comprueba si es un json valido
export function isValidJson(json) {
  try {
    JSON.parse(JSON.stringify(json));
    return true;
  } catch (error) {
    return false;
  }
}

export function clon(json) {
  if (!json) {
    return null;
  }
  return JSON.parse(JSON.stringify(json));
}

export function length(json) {
  if (!json || !isObject(json)) {
    return -1;
  }
  return Object.keys(json).length;
}

// Convierte un JSON en un Array de objetos donde el Key de cada elemento del Json pasa a ser una propiedad
// Si se indica un KeyName, inyecta con ese KeyName el Key del objeto
// Ej: {"123345": {name:"CocaCola"}, ...} => [{"id":"12345", "name":"CocaCola"}, ...]
// Si NO se indica un KeyName, inserta cada objeto tal cual viene, perdiendose su referencia
// Ej: {"123345": {name:"CocaCola"}, ...} => [{"name":"CocaCola"}, ...]
// Devuelve un Array (hace un deep clone de los objetos del array)
export function jsonToArray(json, keyName) {
  if (!json) {
    return [];
  }
  let temp = [];
  const clone = clon(json);
  for (let i in clone) {
    if (!isNull(keyName)) {
      clone[i][keyName] = i;
    }
    temp.push(clone[i]);
  }
  return temp;
}

// Convierte un JSON en un Array donde el Key de cada elemento del Json pasa a ser una propiedad ('id' por defecto)
// Si el json es un nodo final, y no tiene objetos anidados, el valor pasa a ser una propiedad ('value' por defecto)
// Ej: {"123345": "CocaCola"} => [{"id":"12345", "value":"CocaCola"}]
// {1001: "Cola"} => {"id":"1001", "value":"Cola"}
// Devuelve un Array (hace un deep clone de los objetos del array)
export function jsonSimpleToArray(json, keyName = 'id', valueName = 'value') {
  if (!json) {
    return [];
  }
  const clone = clon(json);
  var temp = [];
  for (var o in clone) {
    let obj = {};
    obj[keyName] = o;
    obj[valueName] = clone[o];
    temp.push(obj);
  }
  return temp;
}

// Dado un json de objetos complejos, inyecta en cada objeto una nueva propiedad (llamada id por defecto) con el key de cada objeto
// Ej: {"123345": {"name": "CocaCola"}} => {{"id":"12345", "name":"CocaCola"}}
export function insertIdAsKey(json, newKey = 'id') {
  if (!json) {
    return {};
  }
  var temp = clon(json);
  for (var o in temp) {
    temp[o][newKey] = o;
  }
  return temp;
}


// Reemplaza el nombre de una propiedad con el mismo valor
// Ej: switchKeyName({"123345": {name:"CocaCola"}}, 'name', 'surname') => {"123345": {surname:"CocaCola"}}
export function switchKeyName(json, oldKey, newKey) {
  if (!json || !oldKey || !newKey) {
    return json;
  }
  if (oldKey === newKey) {
    return json;
  }
  for (var key in json) {
    // El value puede ser un Object o un Array
    let len = (isArray(json[key])) ? json[key].length : length(json[key]);
    if (len > 0) {
      switchKeyName(json[key], oldKey, newKey);
    }
    if (key === oldKey) {
      json[newKey] = json[oldKey];
      delete json[oldKey];
    }
  }
  return json;
}


//console.log('switchKeyName(): ', switchKeyName({"product_1": {name:"CocaCola", type: {name:"opt"}}}, 'name', 'surname'));






