/* eslint-disable */
import {getKeys, isNull} from '@/scripts/helpers/ObjectHelpers';
import {enc as CryptoJSEnc, MD5} from 'crypto-js';
import {trim as _trim} from 'lodash';

export function isString(str) {
  return (typeof str === 'string') ? true : false;
}

// Devuelve true si es una cadena y esta vacia, y false en el resto de los casos
export function isEmptyString(str) {
  return (isString(str)) ? str === '' : false;
}

export function length(str) {
  return (isNull(str)) ? 0 : String(str).length;
}


export function toLowerCase(str) {
  return (isString(str)) ? str.toLowerCase() : str;
}

export function toUpperCase(str) {
  return (isString(str)) ? str.toUpperCase() : str;
}

// Primera letra en mayuscula
export function toTitle(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// una-url-valida-sin-espacios
export function toSlug(str = '') {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return str.toString().toLowerCase()
    .replace(/\s+/g, '-')             // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c)))   // Replace special characters
    .replace(/&/g, '-and-')             // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')           // Remove all non-word characters
    .replace(/\-\-+/g, '-')             // Replace multiple - with single -
    .replace(/^-+/, '')               // Trim - from start of text
    .replace(/-+$/, '')							 // Trim - from end of text
}

//console.log('toSlug(): ', '>'+toSlug('hols   \@/* _+..df _ asd fasd fasdf ')+'<');

export function contains(str, bit) {
  if (!isString(str) || isEmptyString(bit)) {
    return false;
  }
  return (str.indexOf(bit) < 0) ? false : true;
}

//console.log('contains(): ', contains('hola', 'h'));

export function uniqueName(desiredName, names) {
  if (typeof desiredName !== 'string') {
    return desiredName;
  }
  if (!Array.isArray(names)) {
    return desiredName;
  }
  // Es un nombre valido, no existe otro igual
  if (names.indexOf(desiredName) < 0) {
    return desiredName;
  }

  desiredName = desiredName + ' (copy)';
  if (names.indexOf(desiredName) < 0) {
    return desiredName;
  } else {
    return uniqueName(desiredName, names);
  }
}

//var name = uniqueName('Manolo', ['Manolo', 'Manolo (copy)', 'Manolo (copy) (copy)']);
//var name = uniqueName('Manolo', ['Manolo', 'Manolo (1)', 'Manolo (1)']);
//console.log('name: ', name);
//console.log('-------------: ');


// Quita las barras de los extremos de una cadena
export function trimSlash(str) {
  return String(str).replace(/^\/|\/$/g, '');
}

// Trim de lodash que evita un bug con la extension GTM/GA Debug que elimina los simbolos de euro ¿?
export function trim(str) {
  return _trim(str);
}

export function removeHtmlTags(str = '') {
  const regex = /(<([^>]+)>)/ig;
  return str.replace(regex, '');
}

// Devuelve un ID aleatorio
export function randomId(id) {
  id = id || 'ID_';
  return id + Math.round(Math.random() * 999999999999999);
}

// Trunca una cadena a un maximo numero de caracteres
export function truncateToLength(str = '', length = 100, ending = '...') {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
//console.log('truncateToLength(): ', truncateToLength('Esto es una prueba de un nombre muy largo', 16));

// Convierte un objeto {param1:value, param2:value...} en un String de variables para enviar por url (param1:value&param2:value...)
export function objectToUrlParams(obj) {
  if (isNull(obj)) {
    return '';
  }
  return getKeys(obj).map((k) => {
    return (k) + '=' + (obj[k]);
  }).join('&');
}

// Encripta en MD5
export function encrypt(str) {
  if (!isString(str)) {
    return null;
  }
  return MD5(str).toString();
}

export function base64Encode(str) {
  const encryptedWord = CryptoJSEnc.Utf8.parse(str);
  return CryptoJSEnc.Base64.stringify(encryptedWord);
}

export function base64Decode(encrypted) {
  const encryptedWord = CryptoJSEnc.Base64.parse(encrypted);
  return CryptoJSEnc.Utf8.stringify(encryptedWord);
}

export function isEmail(msg, multiple = false) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (multiple) {
    re = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})),?\s?)+$/;
  }
  return re.test(String(msg).toLowerCase());
}

export function generateRandomString(newLength = 1, userAlphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") {
  const alphabet = userAlphabet.split("");
  const alphabetLength = alphabet.length;
  let randomLetters = "";
  for (let i = 0; i < newLength; i++) {
    randomLetters += alphabet[Number(Math.floor(Math.random() * alphabetLength))];
  }
  return randomLetters;
}


export function isCIF(str){
  const regExp = /^[ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]$/;
  return regExp.test(String(str))
}

export function isNIF(str){
  const regExp = /^[0-9]{8}[A-HJ-NP-TV-Z]$/;
  return regExp.test(String(str))
}
